import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { graphql } from 'gatsby'
import styles from './post.module.scss'
import PostDetail from '../../components/post-detail'
import Thumb from '../../components/thumb'
import Link from '../../components/link'
import Grid from '../../components/grid'

const PostTemplate = ({ data, pageContext }) => {
  const { frontmatter, html } = data.markdownRemark
  const image =
    get(frontmatter, 'animated_image.publicURL') ||
    get(frontmatter, 'static_image.publicURL')
  const title = get(frontmatter, 'title')
  const taxonomy = get(frontmatter, 'taxonomy')
  const year = get(frontmatter, 'year')
  const link = get(frontmatter, 'link')
  const numRelatedItems = 12

  const relatedThumbs = []
  let relatedPosts = data.allMarkdownRemark.edges.slice(
    pageContext.currentIndex + 1,
    pageContext.currentIndex + numRelatedItems + 1
  )

  if (relatedPosts.length < numRelatedItems - 1) {
    relatedPosts = relatedPosts.concat(
      data.allMarkdownRemark.edges.slice(
        0,
        numRelatedItems - relatedPosts.length
      )
    )
  }

  relatedPosts.forEach(item => {
    const { node } = item
    relatedThumbs.push({
      title: get(node, 'frontmatter.title'),
      path: get(node, 'fields.path'),
      thumbHover: get(node, 'frontmatter.thumb.publicURL'),
      thumb: get(node, 'frontmatter.thumb_blue.publicURL'),
    })
  })

  return (
    <div className={styles.el}>
      <div className={styles.el__content}>
        <nav className={styles.el__nav}>
          <div className={styles.el__nav__list}>
            <Link to="/" className={styles.el__btnBack}>
              Back Home
            </Link>
          </div>
        </nav>
        <PostDetail
          title={title}
          taxonomy={taxonomy}
          image={image}
          link={link}
          year={year}
          notes={html}
        />
      </div>
      <div className={styles.el__footer}>
        <Grid layout="row">
          {relatedThumbs.map(item => {
            return <Thumb key={item.path} {...item} />
          })}
        </Grid>
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        year
        link
        animated_image {
          publicURL
        }
        static_image {
          publicURL
        }
        taxonomy {
          publisher
          developer
          platform
          tag
        }
      }
    }
    allMarkdownRemark {
      edges {
        node {
          fields {
            path
          }
          frontmatter {
            title
            thumb {
              publicURL
            }
            thumb_blue {
              publicURL
            }
          }
        }
      }
    }
  }
`

PostTemplate.defaultProps = {
  data: {},
  pageContext: {},
}

PostTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export default PostTemplate
